import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiService, Pagination, Result } from 'src/app/core/services/api.service';
import { checkPermission, permissionSpec } from '../operators/permission.operator';
import { tap } from 'rxjs/operators';

export interface Tenant {
    uid: string;
    created_at: Date;
    updated_at: Date;
    deleted_at: Date;
    name: string;
    description: string;
    latitude: number;
    longitude: number;
    created_by: string;
    category: string;
    role_level: number;
    joined_at: Date;
    tax_no: string;
    join_code: string;
    type: string;
    verified_at: Date;
}

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    constructor(
        private apiService: ApiService,
    ) { }

    private url = (uri: string, version: string = '/v1'): string => `${version}/tenant${uri}`;

    getMyTenant = async (
        pageIndex = 1,
        pageSize = 20,
        search = '',
        filter = '',
        params: HttpParams = new HttpParams(),
    ): Promise<Result> => {
        return this.apiService.find(this.url(''), pageIndex, pageSize, search, filter, params).toPromise();
    }

    getTenantDescription = async (id: string): Promise<Result> => {
        if (checkPermission(permissionSpec.systemModerator, true)) {
            const r = await this.apiService.get(this.url(`/${id}/role`)).toPromise();
            localStorage.setItem('tenant_authorized', r.data as string);
        }
        return this.apiService.get(this.url(`/${id}`)).toPromise();
    }

    getTenantRole = async (tenantUid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${tenantUid}/role`))
            .pipe(
                tap(
                    (result) => {
                        localStorage.setItem('tenant_authorized', result.data as string);
                    },
                    () => {
                        localStorage.removeItem('tenant_authorized');
                    }
                )
            ).toPromise();
    }

    createNewTenant = async (name: string, description: string): Promise<Result> => {
        return this.apiService.post(this.url(''), { name, description }).toPromise();
    }

    uploadTenantLogo = async (uid: string, logo: string): Promise<Result> => {
        return this.apiService.post(this.url(`/${uid}/logo`), { logo }).toPromise();
    }

    validateTenantName = async (name: string): Promise<boolean> => {
        const r = await this.apiService.post(`/v1/validate/tenant/name`, { name }).toPromise();
        return r.data as boolean;
    }

    getTenantAccount = async (uid: string, waiting: boolean = false): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/accounts`)).toPromise();
    }

    changeTenantAccountRole = async (uid: string, accountId: string, lvl: number): Promise<Result> => {
        return this.apiService.put(this.url(`/${uid}/account/${accountId}/role`), { level: lvl }).toPromise();
    }

    removeTenantAccount = async (uid: string, accountId: string): Promise<Result> => {
        return this.apiService.delete(this.url(`/${uid}/account/${accountId}`), null).toPromise();
    }


    getTenantClient = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/clients`)).toPromise();
    }

    // Tenant statistic
    getTenantStats = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/stats`)).toPromise();
    }

    getTenantRiskStatsSummary = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/stats/risk`)).toPromise();
    }

    getTenantPlace = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/places`)).toPromise();
    }

    getPlaceStats = async (uid: string, placeUid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/places/${placeUid}/stats`)).toPromise();
    }

    // Tenant place
    createNewPlace = (uid: string, name: string, province: string): Promise<Result> => {
        return this.apiService.post(this.url(`/${uid}/places`), { name, province }).toPromise();
    }

    editPlaceName = (uid: string, placeUid: string, name: string, province: string, info: string = ''): Promise<Result> => {
        return this.apiService.put(this.url(`/${uid}/places/${placeUid}`), { name, province, info }).toPromise();
    }

    changePlaceColor = (uid: string, placeUid: string, color: string): Promise<Result> => {
        return this.apiService.put(this.url(`/${uid}/places/${placeUid}`), { color }).toPromise();
    }

    removePlace = (uid: string, placeUid: string): Promise<Result> => {
        return this.apiService.delete(this.url(`/${uid}/places/${placeUid}`), null).toPromise();
    }

    // Tenant authorize
    applyTenantInviteCode = async (inviteCode: string): Promise<Result> => {
        return this.apiService.post(`/v1/auth/invite/tenant`, { invite_code: inviteCode }).toPromise();
    }

    /**
     * API V2
     */
    // Tenant dashboard summary
    getDashboardStats = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/dashboard/stats`, '/v2')).toPromise();
    }

    getDashboardVaccine = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/accumulated/high/risk`, '/v2')).toPromise();
    }

    getTenantDashboardHealthSummary = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/summary/health`, '/v2')).toPromise();
    }

    getTenantDashboardPlacesSummary = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/summary/places`, '/v2')).toPromise();
    }

    getTenantDashboardRiskDetail = async (uid: string, color: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/dashboard/risk/${color}`, '/v2')).toPromise();
    }

    getTenantClients = async (uid: string, p: Pagination | undefined = undefined): Promise<Result> => {
        let params = new HttpParams();
        if (p) {
            params = params.append('page', `${p.pageIndex}`).append('size', `${p.pageSize}`);
        }
        return this.apiService.get(this.url(`/${uid}/clients`, '/v2'), params).toPromise();
    }

    getTenantPlaceInfo = async (uid: string, placeUid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/place/${placeUid}`, '/v2')).toPromise();
    }

    // exportDashboard = async (uid: string): Promise<Result> => {
    //     return this.apiService.get(this.url(`/excel/${uid}/dashboard/export`, '/v2')).toPromise();
    // }

    checkExportAuth = async (uid: string, date: string): Promise<Result> => {
        return this.apiService.post(this.url(`/excel/${uid}/auth`, '/v2'), { date }).toPromise();
    }


    // QRCode
    genTenantMemberQRCode = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/qrcode`, '/v2')).toPromise();
    }

    genPlaceQRCode = async (uid: string, placeUid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/place/${placeUid}/qrcode`, '/v2')).toPromise();
    }

    // Tenant info
    changeTenantLogo = async (uid: string, logo: string): Promise<Result> => {
        return this.apiService.put(this.url(`/${uid}/logo`, '/v2'), { logo }).toPromise();
    }

    changeTenantName = async (uid: string, name: string): Promise<Result> => {
        return this.apiService.put(this.url(`/${uid}/name`, '/v2'), { name }).toPromise();
    }

    changeTenantDescription = async (uid: string, description: string): Promise<Result> => {
        return this.apiService.put(this.url(`/${uid}/description`, '/v2'), { description }).toPromise();
    }

    // Tenant group
    getTenantGroup = async (groupUid: string = ''): Promise<Result> => {
        let url = `/v2/group`;
        if (groupUid !== '') {
            url = `${url}/${groupUid}`;
        }
        return this.apiService.get(url).toPromise();
    }

    getTenantGroupDashboardStats = async (groupUid: string = ''): Promise<Result> => {
        return this.apiService.get(`/v2/group/${groupUid}/stats`).toPromise();
    }

    getTenantGroupSummaryHealth = async (groupUid: string = ''): Promise<Result> => {
        return this.apiService.get(`/v2/group/${groupUid}/summary/health`).toPromise();
    }

    getTenantGroupDashboardHealthRisk = async (groupUid: string, color: string): Promise<Result> => {
        return this.apiService.get(`/v2/group/${groupUid}/dashboard/risk/${color}`).toPromise();
    }

    getTenantRiskTimeline = async (accountId: string, uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/timeline/${accountId}`, '/v2')).toPromise();
    }

    getTenantDashboardVaccineDetail = async (uid: string, type: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/dashboard/vaccine/${type}`, '/v2')).toPromise();
    }

    getTenantDashboardInfectDetail = async (uid: string, type: string): Promise<Result> => {
        return this.apiService.get(this.url(`/${uid}/dashboard/infect/${type}`, '/v2')).toPromise();
    }

    deleteScreeningToday = async (uid: string, accountId: string): Promise<Result> => {
        return this.apiService.delete(this.url(`/${uid}/health/${accountId}`, `/v2`), null).toPromise();
    }

    // permission ra
    getPermissionRa = async (uid: string): Promise<Result> => {
        return this.apiService.get(this.url(`/excel/${uid}/permission/ra`, '/v2')).toPromise();
    }
}
