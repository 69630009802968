import { Component, OnInit, AfterViewInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Tenant, TenantService } from 'src/app/shared/services/tenant.service';
import html2canvas from 'html2canvas';

export interface Place {
    name: string;
    uid: string;
    tenant: Tenant;
}

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss']
})
export class QrcodeComponent implements OnInit, AfterViewInit {
    @ViewChild('downloadLink') downloadLink: ElementRef = {} as ElementRef;
    tenantUid = '';
    placeUid = '';
    place: Place | undefined; // = {} as Place;
    tenant: Tenant | undefined; // {} as Tenant;
    tenantLoading = false;
    private sub: Subscription | undefined;

    qrCode: string | undefined;
    saving = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private tenantService: TenantService,
        private cfRef: ChangeDetectorRef,
    ) { }

    ngOnInit(): void {
    }

    ngAfterViewInit(): void {
        this.sub = this.activatedRoute.params.subscribe(params => {
            this.tenantUid = params.uid;
            this.placeUid = params.placeUid;
            if (this.tenantUid && this.placeUid) {
                this.tenantLoading = true;
                this.tenantService.getTenantPlaceInfo(this.tenantUid, this.placeUid).then((r) => {
                    this.tenantLoading = false;
                    this.place = r.data as Place;
                    this.getplaceCheckInQRCode(this.tenantUid, this.placeUid);
                    console.log(this.place);
                }).catch((err) => {
                    console.log('Get place info error', err);
                });
            } else if (this.tenantUid) {
                this.tenantLoading = true;
                this.tenantService.getTenantDescription(this.tenantUid).then((r) => {
                    this.tenantLoading = false;
                    this.tenant = r.data as Tenant;
                    this.getTenantMemberQRCode();
                }).catch((err) => {
                    console.log('Get tenant info error', err);
                });
            }
        });
    }

    getTenantMemberQRCode = (): void => {
        if (this.tenant) {
            this.tenantService.genTenantMemberQRCode(this.tenant.uid).then((r) => {
                this.qrCode = r.data as string;
            });
        }
    }

    getplaceCheckInQRCode = (tenantUid: string, placeUid: string): void => {
        this.tenantService.genPlaceQRCode(tenantUid, placeUid).then((r) => {
            this.qrCode = r.data as string;
        });
    }

    saveQrCode = (): void => {
        this.saving = true;
        this.cfRef.detectChanges();
        html2canvas(document.body).then(canvas => {
            let filename = 'qrcode.png';
            if (this.tenant) {
                filename = `${this.tenant.name}.png`;
            } else if (this.place) {
                filename = `${this.place.name} - ${this.place.tenant.name}.png`;
            }
            this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
            this.downloadLink.nativeElement.download = filename;
            this.downloadLink.nativeElement.click();
        }).finally(() => {
            this.saving = false;
        });
    }
}
