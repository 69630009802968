import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { checkPermission } from 'src/app/shared/operators/permission.operator';
import { DefinedVar } from 'src/environments/definedvar';
import { AuthService } from 'src/app/core/services/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate, CanActivateChild {
    constructor(
        private router: Router,
        private authService: AuthService,
    ) { }
    checkPermission = async (): Promise<boolean> => {
        const r = await this.authService.isAuthorizedAccess();
        if (!r) {
            this.authService.resetAuthorized();
            this.router.navigateByUrl('/');
            return false;
        }
        if (checkPermission(DefinedVar.RoleSystemViewerLevel, true)) {
            return true;
        }
        this.router.navigateByUrl('/tenant');
        return false;
    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.checkPermission();
    }
    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.canActivate(next, state);
    }
}
