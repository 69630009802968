<div nz-row nzJustify="space-around" nzAlign="middle" class="container">
    <div nz-col nzXs="22" nzSm="20" nzMd="16" nzLg="12" nzXl="8">
        <nz-card>
            <nz-result nzStatus="403" [nzIcon]="verifyingIcon" [nzExtra]="verifyingSubTitle">
                <ng-template #verifyingIcon>
                    <div style="padding-bottom: 16px; font-weight: 600;">COVID19 ใกล้ตัวกว่าที่คิด</div>
                    <ng-template #indicatorTemplate><i nz-icon class="loading" nzType="loading"></i></ng-template>
                    <nz-spin nzSimple [nzSize]="'large'" [nzIndicator]="indicatorTemplate"> </nz-spin>
                </ng-template>
                <ng-template #verifyingSubTitle>
                    ผิดพลาด!! <br />ไม่สามารถทำตามที่ท่านร้องขอได้<br /><br />
                    <button nz-button nzType="primary" routerLink="{{ routerLink }}">กลับสู่หน้าหลัก</button>
                </ng-template>
            </nz-result>
        </nz-card>
    </div>
</div>