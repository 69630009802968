import { decryptSecret } from './util.operator';
/**
 * Permission specify
 */
export const permissionSpec = {
    // Tenant
    tenantGuest: 0,
    tenantViwer: 1,
    tenantAdmin: 2,
    tenantOwner: 3,
    tenantCreator: 4,
    // System
    systemSales: 10,
    systemPresale: 20,
    // Root
    systemModerator: 80,
    systemAdmin: 99,
};

/**
 * Check role level permission
 * @param lvl: number
 * @param system: boolean
 */
export const checkPermission = (lvl: number, system: boolean = false): boolean => {
    const tenantUid = localStorage.getItem('tenant_id') || '';
    const salt = localStorage.getItem('salt') || '';
    const key = system ? 'authorized' : 'tenant_authorized';
    const auth = localStorage.getItem(key) || '';
    if (auth !== '' && salt !== '') {
        const role = decryptSecret(auth, salt);
        if (system) {
            return +role >= lvl;
        }
        if (tenantUid !== '' && role !== '' && role.split('|').length === 2) {
            return role.split('|')[0] === tenantUid && +role.split('|')[1] >= lvl;
        }
    }
    return false;
};


/**
 * Gather my session account id
 */
export const whatIsMyAccountId = (): string => {
    const token = localStorage.getItem('access_token');
    if (!token || token.split('.').length !== 3) {
        return '';
    }
    const claims = JSON.parse(atob(token.split('.')[1])) as { jti: string, iss: number, iat: number, sub: string };
    return claims.sub;
};
