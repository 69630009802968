import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingComponent } from './core/components/landing/landing.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { ErrorComponent } from './core/components/error/error.component';
import { ContentComponent } from './core/layouts/content/content.component';
import { Err404 } from './core/components/error/error.model';
import { QrcodeComponent } from './core/components/qrcode/qrcode.component';

import { AuthGuard } from './core/guards/auth.guard';
import { AdminGuard } from './core/guards/admin.guard';

const routes: Routes = [
    { path: '', component: LandingComponent },
    { path: 'auth', component: AuthComponent },
    { path: 'tenant/:uid/qrcode', component: QrcodeComponent },
    { path: 'tenant/:uid/place/:placeUid/qrcode', component: QrcodeComponent },
    { path: 'console', loadChildren: () => import('./features/console/console.module').then(m => m.ConsoleModule), component: ContentComponent, canActivate: [AuthGuard, AdminGuard] },
    { path: 'tenant', loadChildren: () => import('./features/tenant/tenant.module').then(m => m.TenantModule), component: ContentComponent, canActivate: [AuthGuard] },
    { path: 'group', loadChildren: () => import('./features/group/group.module').then(m => m.GroupModule), component: ContentComponent, canActivate: [AuthGuard] },
    { path: 'report', loadChildren: () => import('./features/report/report.module').then(m => m.ReportModule) },
    { path: 'error', component: ErrorComponent, data: Err404 },
    { path: '**', redirectTo: 'error', pathMatch: 'full' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
