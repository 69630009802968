// This file was generate from script, You should not commit or upload this file to public
// It was contain many system credential for build only
export const config = {
    production: false,
    app_name: 'COVID-19 Tracking Platform Management',
    app_version: 'v1.4.2-rc.128',
    build_time: 'Wed Dec  8 09:34:03 UTC 2021',
    build_commit: 'a499269',
    google_api_key: 'AIzaSyCsvQ62z2GFWU9Uvk78ttKCJGDY2mZAMZs',
};
