<nz-header class="top-header">
    <div nz-row class="header">
        <div nz-col nzFlex="100px">
            <a routerLink="/"><img src="/assets/img/qrcode-header.png" class="header-logo" /></a>
        </div>
        <!-- <div nz-col nzFlex="200px" style="font-weight: 600; color: #07809c;">
            Covid19 Tracking Platform
        </div> -->
        <div nz-col nzFlex="auto" style="text-align: right;">
            <div *ngIf="isAuth; else authMenuTmpl">
                <span class="space-left" *ngIf="profile">
                    <nz-avatar nzIcon="user" [nzSrc]="profile.avatar"></nz-avatar>
                    <span class="space-left tbl-title">
                        {{ profile.name }}
                    </span>
                </span>
                <button nz-button nzType="default" class="space-left"
                    *ngIf="definedVar.RoleSystemViewerLevel | permission: true" nz-dropdown [nzDropdownMenu]="menu"
                    nzPlacement="bottomRight">
                    <i nz-icon nzType="setting" nzTheme="outline"></i> สำหรับผู้ดูแลระบบ
                </button>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                    <ul nz-menu>
                        <li nz-menu-group nzTitle="Moderator">
                            <ul>
                                <li nz-menu-item routerLink="/console"><i nz-icon nzType="dashboard"
                                        nzTheme="outline"></i>
                                    Dashboard</li>
                                <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/tenant"><i nz-icon nzType="partition" nzTheme="outline"></i>
                                    Tenant</li>
                                <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/group"><i nz-icon nzType="partition" nzTheme="outline"></i>
                                    Tenant Group</li>
                                <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/user"><i nz-icon nzType="team" nzTheme="outline"></i> User
                                </li>
                                <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/events"><i nz-icon nzType="sound" nzTheme="outline"></i>
                                    Event Marketing
                                </li>
                                <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/notification"><i nz-icon nzType="bell" nzTheme="outline"></i>
                                    Notification
                                </li>
                                <!-- <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/help"><i nz-icon nzType="question-circle"
                                        nzTheme="outline"></i>
                                    Help & Support
                                </li>
                                <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/feedback"><i nz-icon nzType="bug" nzTheme="outline"></i>
                                    Feedback
                                </li> -->
                                <!-- <li *ngIf="definedVar.RoleSystemModeratorLevel | permission: true" nz-menu-item
                                    routerLink="/console/config"><i nz-icon nzType="tool" nzTheme="outline"></i>
                                    Configuration
                                </li> -->
                            </ul>
                        </li>
                        <li nz-menu-group nzTitle="System Admin"
                            *ngIf="definedVar.RoleSystemRootLevel | permission: true">
                            <ul>
                                <li nz-menu-item routerLink="/console/system"><i nz-icon nzType="database"
                                        nzTheme="outline"></i> System</li>
                            </ul>
                        </li>
                    </ul>
                </nz-dropdown-menu>
                <button nz-button nzType="primary" class="space-left" nzDanger (click)="getSignOut()">
                    <i nz-icon nzType="logout" nzTheme="outline"></i> ออกจากระบบ
                </button>
            </div>
            <ng-template #authMenuTmpl>
                <button nz-button nzType="primary" (click)="getSignIn()">
                    <i nz-icon nzType="login" nzTheme="outline"></i> ลงชื่อเข้าใช้งาน
                </button>
            </ng-template>
        </div>
    </div>
</nz-header>
<!-- 
<nz-modal [(nzVisible)]="newTenantModalVisible" nzTitle="สร้าง Tenant ใหม่" [nzFooter]="modalFooter"
    (nzOnCancel)="closeNewTenantModal()" (nzOnOk)="submitCreateNewTenant()">
    <ng-container *nzModalContent>
        กรุณารอใส่ชื่อ Tenant ที่ต้องการสร้าง <span style="color:red;">*</span>
        <input type="text" style="margin: 8px 0;" [(ngModel)]="newTenantName" nz-input
            placeholder="ใส่ชื่อ Tenant และกดค้นหา" (input)="onNewTenantNameTyping()" />
        <nz-alert nzType="error" nzMessage="{{ errMsg }}" *ngIf="hasErr" nzShowIcon></nz-alert>
    </ng-container>

    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="closeNewTenantModal()"><i nz-icon nzType="close"
                nzTheme="outline"></i> ยกเลิก</button>
        <button nz-button nzType="primary" (click)="submitCreateNewTenant()" [nzLoading]="isInProgress"><i nz-icon
                nzType="save" nzTheme="outline"></i> บันทึก</button>
    </ng-template>
</nz-modal> -->