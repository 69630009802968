<div nz-row nzJustify="center">
    <div nz-col nzSpan="16" style="text-align: center;">
        <img src="/assets/img/qrcode-header.png" width="100%" style="margin-top: 16px; margin-bottom: 8px;">
        <nz-card style="padding: 0px; margin: 8px 0;" *ngIf="qrCode"
            [nzBodyStyle]="{'padding':'8px', 'padding-top': '16px'}">
            <qrcode [qrdata]="qrCode" [width]="200" [errorCorrectionLevel]="'M'" [margin]="0">
            </qrcode>
            <div style="margin: 8px 0; font-size: 1.2rem;" *ngIf="tenant">{{ tenant.name }}</div>
            <div class="tbl-content" style="margin: 8px 0;">
                <span *ngIf="place; else notCheckInQrCodeTmpl">
                    <div class="tbl-content" style="margin: 8px 0; font-size: 1.2rem;">{{ place.name }}</div>
                    <div class="tbl-content" style="margin: 4px 0 8px 0;">{{ place.tenant.name }}</div>
                </span>
                <ng-template #notCheckInQrCodeTmpl>สแกนเพื่อเป็นสมาชิก</ng-template>
            </div>
        </nz-card>
        <button nz-button nzBlock nzType="primary" (click)="saveQrCode()" *ngIf="!saving" style="margin-top: 16px;">
            <i nz-icon nzType="download"></i>
            ดาวโหลด QRCode
        </button>

    </div>
    <a #downloadLink style="display: none;"></a>
</div>