<nz-layout>
    <app-header></app-header>
    <nz-content>
        <div class="inner-content">
            <div style="text-align: center;">
                <div style="color: #676a6c;font-size: 24px;font-weight: 200; margin: 20px;">~ มาร่วมเป็นส่วนหนึ่งกับเรา
                    INET One
                    Platform ~
                </div>
                <div style="color: #676a6c;font-size: 30px;font-weight: 200;">One Account, One Collaboration</div>
                <div style="color: #1ab394;font-size: 30px;font-weight: 200;">
                    <span class="service-list">One Mail</span>
                    <span class="service-list">One Chat</span>
                    <span class="service-list">One Box</span>
                </div>
                <img src="/assets/img/one-service-full.png"
                    style="height: 250px; margin-bottom: 20px;margin-top: 20px;" /><br />
                <div style="margin-bottom: 10px;">สร้าง Account ONE ID กับเรา ใช้ได้ทุกบริการของ INET</div>
                <a nz-button nzSize="large" nzType="primary" style="margin-top: 10px;"
                    href="https://one.th/register">ลงทะเบียนกับเราเลย</a>
            </div>
        </div>
    </nz-content>
    <app-footer></app-footer>
</nz-layout>