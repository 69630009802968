import { Component, OnInit, Input } from '@angular/core';
import { AccountProfile, AuthService } from '../../services/auth.service';
// import { TenantService } from 'src/app/shared/services/tenant.service';
// import { NzMessageService } from 'ng-zorro-antd/message';
import { Router } from '@angular/router';
import { DefinedVar } from 'src/environments/definedvar';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    @Input() isAuth = false;
    profile: AccountProfile | undefined;
    definedVar = DefinedVar;

    // newTenantModalVisible: boolean | undefined;
    // newTenantExistedVerified: boolean | undefined;
    // newTenantName: string | undefined;
    // isInProgress: boolean | undefined;
    // isConfirmed: boolean | undefined;

    // errMsg: string | undefined;
    // hasErr: boolean | undefined;

    constructor(
        private authService: AuthService,
        // private tenantService: TenantService,
        // private msgService: NzMessageService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        if (this.isAuth) {
            this.authService.getProfile().then((r) => {
                this.profile = r.data as AccountProfile;
            });
        }
    }

    getSignIn = (): void => {
        window.location.href = this.authService.getSignInUrl();
    }

    getSignOut = (): void => {
        this.authService.signOut().then(() => {
            window.location.href = '/';
        });
    }

    getAdminConsole = (): void => {
        this.router.navigateByUrl('/console');
    }

    // showNewTenantModal = (): void => {
    //     this.newTenantModalVisible = true;
    // }

    // closeNewTenantModal = (): void => {
    //     // Clear input
    //     this.newTenantModalVisible = false;
    //     this.newTenantName = '';

    //     // Clear error
    //     this.hasErr = false;
    //     this.errMsg = '';
    // }

    // onNewTenantNameTyping = (): void => {
    //     if (this.hasErr) {
    //         this.hasErr = false;
    //         this.errMsg = '';
    //     }
    // }

    // submitCreateNewTenant = (): void => {
    //     if (this.newTenantName && this.newTenantName.trim().length > 4 && this.newTenantName.trim.length < 1024) {
    //         this.tenantService.createNewTenant(this.newTenantName).then((r) => {
    //             this.msgService.success(`สร้าง Tenant ${this.newTenantName} สำเร็จ`);
    //             this.closeNewTenantModal();
    //         }).catch((err) => {
    //             if (err.status === 409) {
    //                 this.errMsg = 'มี Tenant ชื่อนี้อยู่แล้ว กรุณาลองอีกครั้ง';
    //             } else if (err.status === 409) {
    //                 this.errMsg = 'คุณไม่มีสิทธิ์สร้าง Tenant ใหม่ได้';
    //             } else {
    //                 this.errMsg = 'ไม่สามารถสร้าง Tenant ใหม่ กรุณาติดต่อนักพัฒนา';
    //             }
    //             this.hasErr = true;
    //             console.log(`Create new tenant error`, err);
    //         });
    //     } else {
    //         this.errMsg = 'ชื่อ Tenant ไม่ถูกต้อง ลองใหม่อีกครั้ง';
    //         this.hasErr = true;
    //     }
    // }
}
