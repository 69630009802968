import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { config } from 'src/environments/config';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(
        private title: Title,
    ) {
        let appName = `${config.app_name} - ${config.app_version}`;
        if (!config.production) {
            appName = `${appName} (${config.build_commit})`;
        }
        this.title.setTitle(appName);
    }

    ngOnInit(): void {
    }
}
