// This file was automate generate from backend Golang.
// !! Do not try to modify to this file because it will be replace.
export const DefinedVar = {
    RoleTenantOwnerLevel: 18,
    CheckInViaScreening: 'screening_process',
    RiskGreen: 'green',
    RoleTenantGuestLevel: 0,
    RoleTenantClientLevel: 2,
    RoleTenantCreatorLevel: 19,
    RoleSystemViewerLevel: 90,
    CheckInViaApiClient: 'api_client_checkin',
    CheckInViaOneChatCheckIn: 'onechat_checkin',
    CheckInViaThaiChana: 'thaichana_qrcode_checkin',
    RiskYellow: 'yellow',
    RoleTenantMemberLevel: 1,
    RoleTenantStaffLevel: 10,
    RoleTenantAdminLevel: 17,
    RoleSystemSalesLevel: 50,
    ManagementSession: 'management_session',
    RiskOrange: 'orange',
    RiskRed: 'red',
    ServerKey: 'jKTsWddB7Ot1tWEgW80lZpmbdrxc4HwB',
    RoleSystemPresaleLevel: 60,
    RoleSystemModeratorLevel: 98,
    RoleSystemRootLevel: 99,
    CitizenSession: 'citizen_session',
    CheckInViaQrCodeCheckIn: 'qrcode_checkin',
};
