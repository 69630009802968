import { Component, OnInit } from '@angular/core';
import { checkPermission, permissionSpec } from 'src/app/shared/operators/permission.operator';

@Component({
    selector: 'app-error',
    templateUrl: './error.component.html',
    styleUrls: ['./error.component.scss']
})
export class ErrorComponent implements OnInit {
    routerLink = '/';
    constructor() { }

    ngOnInit(): void {
        if (checkPermission(permissionSpec.systemModerator, true)) {
            this.routerLink = '/console';
        } else if (checkPermission(permissionSpec.systemSales)) {
            this.routerLink = '/tenant';
        }
    }
}
