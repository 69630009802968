import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { checkPermission } from 'src/app/shared/operators/permission.operator';
import { DefinedVar } from 'src/environments/definedvar';

interface VerifyAccess {
    code: string | null;
    onechat_token: string | null;
    onechat_sign: string | null;
    redirect_url: string | null;
}

const DefaultRedirectUrl = '/tenant';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
    verifying = true;
    isAuth = false;
    verifyAccess: VerifyAccess = {} as VerifyAccess;

    constructor(
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((p) => {
            this.verifyAccess.code = p.get('code');
            this.verifyAccess.onechat_token = p.get('onechat_token');
            this.verifyAccess.redirect_url = p.get('redirect_url');
            // If verify oneid authorized code
            if (this.verifyAccess.code) {
                this.verifyAccess.code = decodeURIComponent(this.verifyAccess.code).replace(/\s/gi, '+');
                this.authService.verifyByOneIdAuthorizedCode(this.verifyAccess.code).then(
                    (r) => {
                        this.isAuth = true;
                        if (this.verifyAccess.redirect_url) {
                            window.location.href = this.verifyAccess.redirect_url;
                        } else {
                            window.location.href = this.redirectUrl();
                        }
                    });
            } else if (this.verifyAccess.onechat_token) {
                this.verifyAccess.onechat_token = decodeURIComponent(this.verifyAccess.onechat_token).replace(/\s/gi, '+');
                this.authService.verifyByOneChatToken(this.verifyAccess.onechat_token).then(
                    (r) => {
                        this.isAuth = true;
                        if (this.verifyAccess.redirect_url) {
                            window.location.href = this.verifyAccess.redirect_url;
                        } else {
                            window.location.href = this.redirectUrl();
                        }
                    });
            } else {
                this.authService.isAuthorizedAccess().then(
                    (r) => {
                        if (r) {
                            this.isAuth = true;
                            window.location.href = this.redirectUrl();
                            return;
                        }
                        window.location.href = '/';
                    }
                );
            }
        });
    }

    redirectUrl = (): string => {
        if (checkPermission(DefinedVar.RoleSystemViewerLevel, true)) {
            return '/console';
        }
        return DefaultRedirectUrl;
    }
}
