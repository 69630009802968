import { BrowserModule } from '@angular/platform-browser';
import { Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Core import
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { QRCodeModule } from 'angularx-qrcode';

// Locale
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);

/** Http interceptor providers in outside-in order */
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthenInterceptor } from './core/interceptors/auth.interceptor';
export const httpInterceptorProviders = [
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthenInterceptor,
        multi: true
    },
];

// Ng Zorro Theme
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzMessageModule } from 'ng-zorro-antd/message';

// Pipe
import { PermissionPipe } from './core/pipes/permission.pipe';

// Components
import { LandingComponent } from './core/components/landing/landing.component';
import { HeaderComponent } from './core/layouts/header/header.component';
import { FooterComponent } from './core/layouts/footer/footer.component';
import { ContentComponent } from './core/layouts/content/content.component';
import { ErrorComponent } from './core/components/error/error.component';
import { AuthComponent } from './core/components/auth/auth.component';
import { QrcodeComponent } from './core/components/qrcode/qrcode.component';

@NgModule({
    declarations: [
        AppComponent,
        LandingComponent,
        HeaderComponent,
        FooterComponent,
        ContentComponent,
        ErrorComponent,
        AuthComponent,
        QrcodeComponent,
        // Pipe
        PermissionPipe,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        QRCodeModule,
        // Ng Zorro
        NzLayoutModule,
        NzGridModule,
        NzCardModule,
        NzDividerModule,
        NzButtonModule,
        NzIconModule,
        NzListModule,
        NzResultModule,
        NzSpinModule,
        NzAvatarModule,
        NzDropDownModule,
        NzModalModule,
        NzInputModule,
        NzFormModule,
        NzAlertModule,
        NzMessageModule,
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        Title,
        httpInterceptorProviders,
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
