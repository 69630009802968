<div nz-row nzJustify="space-around" nzAlign="middle" class="container">
    <div nz-col nzSpan="8">
        <nz-card>
            <img src="/assets/img/covidtracking_header.jpg" width="100%" />
            <nz-result [nzIcon]="verifyingIcon" [nzExtra]="verifyingSubTitle">
                <ng-template #verifyingIcon>
                    <div style="padding-bottom: 16px; font-weight: 600;">COVID19 ใกล้ตัวกว่าที่คิด</div>
                    <ng-template #indicatorTemplate><i nz-icon class="loading" nzType="loading"></i></ng-template>
                    <nz-spin nzSimple [nzSize]="'large'" [nzIndicator]="indicatorTemplate"> </nz-spin>
                </ng-template>
                <ng-template #verifyingSubTitle>
                    กำลังตรวจสอบข้อมูลผู้ใช้งาน <br />
                    กรุณารอสักครู่...
                </ng-template>
            </nz-result>
        </nz-card>
    </div>
</div>