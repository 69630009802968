import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { checkPermission } from 'src/app/shared/operators/permission.operator';
import { DefinedVar } from 'src/environments/definedvar';

const DefaultConsoleRouter = '/console';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
    verify = true;

    constructor(
        private authService: AuthService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.authService.isAuthorizedAccess().then((r) => {
            if (r as boolean) {
                if (checkPermission(DefinedVar.RoleSystemModeratorLevel, true)) {
                    this.router.navigateByUrl(DefaultConsoleRouter);
                    return;
                }
                this.router.navigateByUrl('/tenant');
            }
        }).finally(() => {
            this.verify = false;
        });
    }

    getSignIn = (): void => {
        window.location.href = this.authService.getSignInUrl();
    }

}
